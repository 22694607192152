import { render, staticRenderFns } from "./index.vue?vue&type=template&id=52bf6a36"
import script from "./index.vue?vue&type=script&setup=true&lang=ts"
export * from "./index.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=52bf6a36&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@align/ui/dist/runtime/atoms/Copy/Copy.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default,CompositionSpacer: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,PushButton: require('/app/node_modules/@align/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,Icon: require('/app/node_modules/@align/ui/dist/runtime/atoms/Icon/Icon.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default,CompositionLayoutSingle: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/Composition.vue').default})
